import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Slider from 'rc-slider';

import theme from '../../styles/theme/theme';

const StyledSlider = styled.div`
  .scale-values {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    color: ${(p) => p.theme.colors.textGrey};
  }

	.rc-slider {
		position: relative;
		height: 2.2rem;
    margin-bottom: 2rem;

		@media (max-width: 1087px) {
			max-width: 90%;
			margin: 0 auto;
      margin-bottom: 2rem;
		}

		.rc-slider-rail {
			position: absolute;
			background: ${theme.colors.lightest};
			height: 100%;
			width: 100%;
			border-radius: 12px;
		}

		.rc-slider-track {
			position: absolute;
			background-color: ${theme.colors.tertiary};
			height: 100%;
			width: 100%;
			border-radius: 12px;
		}

		.rc-slider-handle {
			height: 3.2rem;
			width: 3.2rem;
			background-color: ${theme.colors.tertiary};
			border-radius: 50%;
			position: absolute;
			top: 50%;
      transform: translate(-50%, -50%) !important;
      
      &:focus {
        outline: none;
      }

			&::before {
				content: '${(props) => props.value}';
				position: absolute;
				top: -5.5rem;
				left: 50%;
				transform: translateX(-${(props) => props.handlePosition}%);
        border-radius: 50%;
        font-size: 2.2rem;
        font-weight: ${(props) => props.theme.fonts.weights.bold};
        color: ${(props) => props.theme.colors.lightest};
			}

			&::after {
				content: '';
				height: 5.9rem;
				width: 5.9rem;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-color: rgba(162, 140, 68, 0.5);
				border-radius: 50%;
			}
		}
  }

  .savings {
    margin-top: 5rem;
    border: 2px solid ${(props) => props.theme.colors.lightest};
    display: inline-block;

		@media (max-width: 768px) {
			width: 45rem;
			max-width: 100%;
		}

    p {
      font-size: 2.4rem;
      display: flex;
      flex-direction: column;
			align-items: stretch;
			justify-content: center;
			flex-wrap: wrap;
      color: ${(p) => p.theme.colors.primary};
      font-weight: ${(p) => p.theme.fonts.weights.semiBold};
      background-color: ${(p) => p.theme.colors.lightest};
      
      
			@media (min-width: 769px) {
        height: 8rem;
        flex-direction: row;
			}

      span {
				display: flex;
				flex-direction: column;
				justify-content: center;
        align-items: center;
				
				@media (max-width: 768px) {
					width: 100%;
          min-height: 7.5rem;
				}

        &:nth-child(1) {
					/* height: 7.4rem; */
          font-family: ${(props) => props.theme.fonts.secondary};
					font-weight: ${(props) => props.theme.fonts.weights.semiBold};
					padding: 0 1em;
					
					@media (min-width: 769px) {
						padding: 0 2em;
					}
        }

        &:nth-child(2) {
					/* height: 7.4rem; */
          font-size: 4.5rem;
          color: ${(props) => props.theme.colors.lightest};
          font-weight: ${(props) => props.theme.fonts.weights.bold};
					background-color: ${(props) => props.theme.colors.primary};
					padding: 0 1em;

					@media (min-width: 769px) {
						padding: 0 1.4em;
					}
        }
      }
    }
  }

  small {
    display: block;
    font-size: 1.6rem;
    color: ${(props) => props.theme.colors.textDarkerGrey};
    line-height: 1.5;
    max-width: 44em;
    margin-top: 3rem;
    margin-right: auto;
    margin-left: auto;
  }
`;

const startValue = 600000;
const minValue = 100000;
const maxValue = 3000000;

const currencyFormatted = (value) => {
	const currencyInt = parseInt(value, 10);

	const formatted = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 0,
	}).format(currencyInt);

	return formatted;
};

const calcPositionPercentage = (value, min, max) => {
	const base = (value / (max - min)) * 100;
	const rounded = base.toFixed(0);
	return rounded;
};

const SavingsSlider = () => {
	const [currentValue, setCurrentValue] = useState(startValue);
	const [handlePosition, setHandlePosition] = useState(undefined);

	useEffect(() => {
		setHandlePosition(calcPositionPercentage(startValue, minValue, maxValue));
	}, []);

	const sliderOnChange = (value) => {
		setHandlePosition(calcPositionPercentage(value, minValue, maxValue));
		setCurrentValue(value);
	};

	return (
		<StyledSlider
			value={currencyFormatted(currentValue)}
			handlePosition={handlePosition}
		>
			<Slider
				min={minValue}
				max={maxValue}
				step={50000}
				onChange={(value) => sliderOnChange(value)}
				defaultValue={startValue}
			/>
			<div className="scale-values">
				<span className="scale-value">{currencyFormatted(minValue)}</span>
				<span className="scale-value">{currencyFormatted(maxValue)}</span>
			</div>
			<div className="savings">
				<p>
					<span>You Could Save: </span>
					<span>{currencyFormatted(currentValue * 0.01695)}*</span>
				</p>
			</div>
			<small>
				*When compared to a commission of 5%, taking into account our 1% listing
				fee and a commission of 2.5% to the co-operating brokerage (recommended
				but not required).
			</small>
		</StyledSlider>
	);
};

export default SavingsSlider;
